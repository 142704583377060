
.form-grupin{
    position: relative;
    margin-bottom: 25px;
  }

  .checked-person{
    text-align: -webkit-center;
  }
  .container-checked{
    background-color: green;
   width: min-content;
   padding: 3%;
    border-radius: 100%;
  }
  .container-close{
    background-color: red;
   width: min-content;
   padding: 3%;
    border-radius: 100%;
  }
