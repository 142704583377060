
.form-grupin{
    position: relative;
    margin-bottom: 25px;
  }
  .popup{
    width: 100%;
    padding-bottom: 10%;
  }

 