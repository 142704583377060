.buttonhref {     
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;        
}

.btncrear{
    width: 100%;
    background-color:#00C4A3 !important;
    color: white;
}

