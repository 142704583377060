
.form-grupin{
    position: relative;
    margin-bottom: 25px;
  }

  .checked-person{
    text-align: -webkit-center;
  }
  .container-checked{
    background-color: green;
   width: min-content;
   padding: 3%;
    border-radius: 100%;
  }
  .container-close{
    background-color: red;
   width: min-content;
   padding: 3%;
    border-radius: 100%;
  }
  .loader-carga {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    align-self: center;
    margin: 15%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }