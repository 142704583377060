



.p-multiselect{
    box-shadow: 0 0 0 0.02rem grey ;
}

.p-multiselect-items {
    padding: 0;
    
}
.p-multiselect-item{
    background-color: white;
    color: black;
}
.p-multiselect-panel{
    background-color: white;
    box-shadow: 0 0 0 0.02rem grey;
}

.p-checkbox-box{
    box-shadow: 0 0 0 0.04rem grey;
}